<template>
  <div class="user-page container-fluid h-100">

    <div class="d-flex flex-column justify-content-between h-100">
      <div>

        <div class="h-top">
          <router-link :to="{path: '/'}" class="button-back-empty">
            <span class="fa fa-arrow-left"></span>
          </router-link>
        </div>

        <div class="h-title">
          New account
        </div>

        <form class="col-md-4 offset-md-4 h-form" @submit.prevent="doUserRegister">

          <div class="form-group mb-3">
            <input
                v-model="email"
                :class="{'is-invalid': errors?.email}"
                type="text"
                class="form-control rounded-4"
                id="email-input"
                autocomplete="false"
                placeholder="Email" />
            <div class="invalid-feedback">{{ errors?.email }}</div>
          </div>

          <div class="form-group mb-3">
            <input
                v-model="password"
                :class="{'is-invalid': errors?.password}"
                type="password"
                class="form-control rounded-4"
                id="password-input"
                autocomplete="false"
                placeholder="Password" />
            <div class="invalid-feedback">{{ errors?.password }}</div>
          </div>

          <button class="btn btn-form mt-2" type="submit">
            Let’s get started
          </button>

          <div class="h-subtext">
            By clicking, you agree to
            <router-link to="/page/agreement">the terms of use</router-link> and
            <router-link to="/page/rules">visit rules</router-link>.
          </div>

        </form>

      </div>
      <div class="h-bottom">
        ALREADY HAVE ACCOUNT?
        <router-link to="/user/login">Sign in</router-link>
      </div>
    </div>

  </div>
</template>

<script>

export default {

  data: () => ({
    email: null,
    password: null
  }),

  mounted() {

    if (this.isUser())
      return this.redirect('/');
  },

  methods: {

    async doUserRegister() {

      let apiHolder = await this.sendApiPost('api/user/register', {
        email: this.email,
        password: this.password
      });

      if (!apiHolder.isSuccess())
        return;

        await this.redirect('/user/login', 'Instructions for confirmation your account have been sent to your email address',
            'success');
    }

  },

}
</script>

<style scoped>

.h-title {
  padding-top: 80px;
}

</style>